import Container from "react-bootstrap/esm/Container";
import Navbar from "react-bootstrap/esm/Navbar";
import Nav from "react-bootstrap/esm/Nav";
import NavDropdown from "react-bootstrap/esm/NavDropdown";
import { Button } from "react-bootstrap";

export default function Content() {
  return (
    <div className="Page Page_Home">
      <p className="BodyText">Welcome to Nova Studios!</p>
      <br />
      <p className="MOTDText">
        Make sure to check out our products!
      </p>
      <ul className="ProductsList">
        <li>Orbit Alpha (NC3)</li>
      </ul>
    </div>
  );
}
