import Container from "react-bootstrap/esm/Container";
import Navbar from "react-bootstrap/esm/Navbar";
import Nav from "react-bootstrap/esm/Nav";
import NavDropdown from "react-bootstrap/esm/NavDropdown";
import { Link } from "react-router-dom";
import Footer from "Components/Footer/Footer";

interface IPageProps {
  Element: JSX.Element;
}

export default function Page(props: IPageProps) {
  return (
    <div className="App">
      <Navbar className="NavBar" variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand className="App-Name" href="/">
            Nova Studios
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-dark-example" />
          <Navbar.Collapse id="navbar-dark-example">
            <Nav>
              <NavDropdown
                className="NavbarDropdown"
                id="nav-dropdown-dark-example"
                title="Products"
                menuVariant="dark"
              >
                <NavDropdown.Item>
                  <Link className="Link" to="/NC3">
                    Orbit Alpha
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="PageContainer BackgroundImage">
        {props.Element}
      </div>
      <Footer />
    </div>
  );
}
