import React  from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Page from 'Page';
import NC3 from 'Pages/NC3/NC3';
import Home from 'Pages/Home/Home';
import NotFound from 'Pages/ErrorPages/NotFound';
import SpotCal from 'Pages/SpotifyCalculator/SpotCal';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Page Element={<NotFound/>}/>}/>
        <Route path="/" element={<Page Element={<Home/>}/>}/>
        <Route path="/NC3" element={<Page Element={<NC3/>}/>}/>
        <Route path="/spotcal" element={<Page Element={<SpotCal/>}/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
