import React from "react";

export interface IProductImage {
    ImageUrl: string,
    Description: string,
    Header: string,
    blur?: boolean,
    Right?: unknown
}

export default class ProductImage extends React.Component<IProductImage> {
    ImageUrl: string;
    Description: string;
    Header: string;
    blur?: boolean;
    Right?: unknown;

    constructor (props: IProductImage) {
      super(props);
      this.ImageUrl = props.ImageUrl;
      this.Description = props.Description;
      this.Header = props.Header;
      this.blur = props.blur;
      this.Right = props.Right;
    }

    render() {
      const styles = {
        filter: (this.blur)? "blur(20px)" : "blur(0px)",
        width: "100%",
        height: "100%"
      }
      if (this.Right)
        return (
        <div style={{display: 'inline-flex'}}>
          <div style={{width: '25%'}}>
            <p className="ProductImage-Header">{`${this.Header}`}</p>
            <p>{`${this.Description}`}</p>
          </div>
          <div className="ProductImage-Right" style={{overflow: "hidden"}}>
            <img src={`${this.ImageUrl}`} alt="NC3" style={styles}></img>
          </div>
        </div>
        );
      else
        return (
        <div style={{display: 'inline-flex'}}>
          <div className="ProductImage-Left" style={{overflow: "hidden"}}>
            <img src={`${this.ImageUrl}`} alt="NC3" style={styles}></img>
          </div>
          <div style={{width: '25%'}}>
            <p className="ProductImage-Header">{`${this.Header}`}</p>
            <p>{`${this.Description}`}</p>
          </div>
        </div>
        );
    }
}
