import Container from "react-bootstrap/esm/Container";
import { Button, Dropdown } from "react-bootstrap";
import ProductImage from "Components/ProductImage"
import moment from "moment";

async function getModDate(url: string) : Promise<string> {
  try {
    const res = await fetch(url, {method: 'HEAD'});
    return res.headers.get('Last-Modified') || '';
  }
  catch {return ''}
}

export default function NC3() {
  getModDate('https://cdn.novastudios.uk/NovaChatUIBuilds/Orbit-Windows-Latest.exe').then((res) => {
    const d = document.getElementById('WinBuild');
    if (d === undefined || d === null) return;
    let x = moment(new Date(res)).from(Date.now());
    if (x === "Invalid date") x = "Never";
    d.innerText = `Last Build: ${x}`;
  });

  getModDate('https://cdn.novastudios.uk/NovaChatUIBuilds/Orbit-Linux-Latest.AppImage').then((res) => {
    const d = document.getElementById('LinuxBuild');
    if (d === undefined || d === null) return;
    let x = moment(new Date(res)).from(Date.now());
    if (x === "Invalid date") x = "Never";
    d.innerText = `Last Build: ${x}`;
  });

  getModDate('https://cdn.novastudios.uk/NovaChatUIBuilds/Orbit-MacOS-x64-Latest.dmg').then((res) => {
    const d = document.getElementById('MacBuild');
    if (d === undefined || d === null) return;
    let x = moment(new Date(res)).from(Date.now());
    if (x === "Invalid date") x = "Never";
    d.innerText = `Last Build: ${x}`;
  });

  getModDate('https://cdn.novastudios.uk/NovaChatUIBuilds/Orbit-Android-Latest.apk').then((res) => {
    const d = document.getElementById('AndroidBuild');
    if (d === undefined || d === null) return;
    let x = moment(new Date(res)).from(Date.now());
    if (x === "Invalid date") x = "Never";
    d.innerText = `Last Build: ${x}`;
  });

  getModDate('https://cdn.novastudios.uk/NovaChatUIBuilds/Orbit-IOS-Latest.ipa').then((res) => {
    const d = document.getElementById('IOSBuild');
    if (d === undefined || d === null) return;
    let x = moment(new Date(res)).from(Date.now());
    if (x === "Invalid date") x = "Never";
    d.innerText = `Last Build: ${x}`;
  });

  return (
    <div className="Page Page_NC3">
      <div className="ProductSection">
        <Container className="ProductSectionInner">
          <header className="ProductName">Orbit Alpha</header>
          <p className="ProductDescription">
            Nova Studios' pristine chat client is back, this time with a fresh new
            look and complete backend overhaul!
          </p>
          <Button onClick={() => {
            const element = document.querySelector(".DownloadSection");
            if (element != null) element.scrollIntoView({
              behavior: 'smooth'
            });
          }}><i className="bi bi-download" /> Download</Button>
          <Button style={{marginLeft: "5px"}} href="https://orbit.novastudios.uk">Or Try Our Web Client</Button>
          <br />
          <br />
          <ProductImage Description="Simple Interface design for ease of use." ImageUrl="images/interface.png" Header="Simple. Clean."></ProductImage>
          <br />
          <ProductImage Description="With End-To-End Encryption we ensure that everything is secure and private. Only the recipient can read your messages. You are truely annoymous with Orbit." ImageUrl="images/encryption.jpg" Header="Secure. Private." Right></ProductImage>
          <br />
          <ProductImage Description="Chat with friends from anywhere no matter what the device or platform. With support for Windows, Linux and MacOS. Along with Android and iOS, you'll always be able to keep up with your friends!. Don't wanna install anything? There is also an available Web App!" ImageUrl="images/cross_platform.jpg" Header="Cross-platform"></ProductImage>
          <br />
        </Container>
      </div>
      <br />
      <div className="DownloadSection" id="Downloads">
        <header className="SubHeader">Desktop Downloads</header>
        <div className="DownloadLinksContainer">
          <br/>
          <Dropdown className="DropdownContainer" style={{margin: "10px"}}>
            <Dropdown.Toggle className="DropdownButton" split variant="primary" id="dropdown-split-basic">
              <div>
                <div className="DropdownButtonContent">
                  <i className="bi bi-windows" /> Windows
                </div>
                <div id="WinBuild" className="DownloadButtonSubText">
                    Last Build:
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="DropdownMenu">
              <Dropdown.Item className="DropdownMenuItem" href="https://cdn.novastudios.uk/NovaChatUIBuilds/Orbit-Windows-Latest.exe">X64</Dropdown.Item>
              <Dropdown.Item className="DropdownMenuItem" href="" disabled={true}>ARM64</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="DropdownContainer" style={{margin: "10px"}}>
            <Dropdown.Toggle className="DropdownButton" split variant="primary" id="dropdown-split-basic">
              <div>
                <div className="DropdownButtonContent">
                  <i className="bi bi-apple" /> MacOS
                </div>
                <div id="MacBuild" className="DownloadButtonSubText">
                    Last Build:
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="DropdownMenu">
              <Dropdown.Item className="DropdownMenuItem" href="https://cdn.novastudios.uk/NovaChatUIBuilds/Orbit-MacOS-x64-Latest.dmg">Intel</Dropdown.Item>
              <Dropdown.Item className="DropdownMenuItem" href="https://cdn.novastudios.uk/NovaChatUIBuilds/Orbit-MacOS-arm64-Latest.dmg">Apple Silicon</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="DropdownContainer" style={{margin: "10px"}}>
            <Dropdown.Toggle className="DropdownButton" split variant="primary" id="dropdown-split-basic">
              <div>
                <div className="DropdownButtonContent">
                  <i className="bi bi-x-diamond-fill" /> Linux
                </div>
                <div id="LinuxBuild" className="DownloadButtonSubText">
                    Last Build:
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="DropdownMenu">
              <Dropdown.Item className="DropdownMenuItem" href="https://cdn.novastudios.uk/NovaChatUIBuilds/Orbit-Linux-Latest.AppImage">AppImage</Dropdown.Item>
              <Dropdown.Item className="DropdownMenuItem" href="DEBPackage" disabled={true}>DEB (Debaian)</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="DownloadSection">
        <header className="SubHeader">Mobile Downloads</header>
        <div className="DownloadLinksContainer">
          <br/>
          <Dropdown className="DropdownContainer" style={{margin: "10px"}}>
            <Dropdown.Toggle className="DropdownButton" split variant="primary" id="dropdown-split-basic">
              <div>
                <div className="DropdownButtonContent">
                  <i className="bi bi-windows" /> Android
                </div>
                <div id="AndroidBuild" className="DownloadButtonSubText">
                    Last Build:
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="DropdownMenu">
              <Dropdown.Item className="DropdownMenuItem" href="https://cdn.novastudios.uk/NovaChatUIBuilds/Orbit-Android-Latest.apk">ARM</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="DropdownContainer" style={{margin: "10px"}}>
            <Dropdown.Toggle className="DropdownButton" split variant="primary" id="dropdown-split-basic">
              <div>
                <div className="DropdownButtonContent">
                  <i className="bi bi-apple" /> IOS
                </div>
                <div id="IOSBuild" className="DownloadButtonSubText">
                    Last Build:
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="DropdownMenu">
              <Dropdown.Item className="DropdownMenuItem" href="https://cdn.novastudios.uk/NovaChatUIBuilds/Orbit-IOS-Latest.ipa">Apple Silicon</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
