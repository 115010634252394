import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className="Footer">
      <div className="FooterItem">
        <h3>Nova Studios Ltd.</h3>
        <p>&#169; Nova Studios 2022</p>
      </div>
      <div className="FooterLinksSection FooterItem">
        <h4>Links</h4>
        <div className="FooterLinksContainer">
          <Link className="FooterLink" to="/">Home</Link>
          <Link className="FooterLink" to="/">Privacy Policy</Link>
          <Link className="FooterLink" to="/">Terms and Conditions</Link>
        </div>
      </div>
    </div>
  );
}
