export default function SpotCal() {
  return (
    <div className="Page_SpotCal">
      <header className="Page_SpotCal_Name">Spotify Data Calculator</header>
      <p>
        A fun little tool to calculate the amount of data used
        based on the number of minutes from your spotify wrapped.
        Simply input the number of minutes from your spotify wrapped
        and the quality level you have spotify set to and click "Calculate"!
      </p>
      <div className="Page_SpotCal_Calculator">
        <div className="Page_SpotCal_Calculator_Bar">
          <input className="Page_SpotCal_Calculator_Input" type="number"></input>
          <span className="Page_SpotCal_Calculator_Text">Minutes</span>
        </div>
      </div>
    </div>
  );
}
