import { Button } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import "./404.css";


export default function NotFound() {
  return (
    <div className="Page Page_404">
      <Container className="PageInner">
        The page {document.location.pathname} has either moved, no longer exists, or never existed.
        <br/>
        <Button href="/">
         <i className="bi bi-house"/> Return Home
        </Button>
      </Container>
    </div>
  );
}
